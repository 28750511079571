import React from 'react';
import { Link } from 'react-router-dom';

function CallToActionAudit() {
  return (
    <>
      <div className="swatch-1 main-section design-guide-section ">
           <aside className="">
                <h3 className="non-em" >
                   <strong>Ready To Get Started?</strong>
                </h3>
               <br/>
                <h3>
                    <Link to="/Contact" className="btn indent">Book Now</Link>
                </h3>
            </aside>
        </div>
    </>
  );
}

export default CallToActionAudit;
