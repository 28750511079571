import React from 'react';
import ReactDOM from 'react-dom';
import './resources/css/styles.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import BrandAudit from './Pages/BrandAudit';
import CaseStudy from './Components/CaseStudy';
import CaseStudies from './Pages/CaseStudies';

ReactDOM.render(
  <React.StrictMode>
    <body className="swatch-3">
      <Router>
      <ScrollToTop />
      <Header />
        <Route path='/' component={Home} exact/>
        <Route path='/About' component={About} exact/>
        <Route path='/Contact' component={Contact} exact/>
        <Route path='/Services' component={Services} exact/>
        <Route path='/Services/BrandAudit' component={BrandAudit} exact/>
        <Route path='/CaseStudies/:name' component={CaseStudy} />
        <Route path='/CaseStudies' component={CaseStudies} />
      <Footer />
      </Router>
    </body>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();