import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudies from '../Database/CaseStudies';

function Services() {
    const AS = CaseStudies.find (cs => cs.cSName === `AuditionShowdown` );
    const DC = CaseStudies.find (cs => cs.cSName === `DobsonCentre` );
    const KL = CaseStudies.find (cs => cs.cSName === `KhaosLabs` );
    const VOT = CaseStudies.find (cs => cs.cSName === `VentOverTea` );

    const Clients = [];
    for (const cs of CaseStudies) {
        Clients.push(
            <>
            <section className="">
                <h4>{cs.Client}</h4>
                <h3>{cs.Campaign}</h3>
                <h5>
                    <ul>
                        <li>{cs.SkillSet}</li>
                    </ul>
                    <Link to={`/CaseStudies/${cs.cSName}`} >Learn More
                    </Link>
                </h5>
            </section><br/>
        </>
        )
    }

    const ASSkill = []
    for (const skills of AS.SkillSet) {
        ASSkill.push(
            <li>
                {skills}
            </li>
        )
    };

    const DCSkill = []
    for (const skills of DC.SkillSet) {
        DCSkill.push(
            <li>
                {skills}
            </li>
        )
    };

    const KLSkill = []
    for (const skills of KL.SkillSet) {
        KLSkill.push(
            <li>
                {skills}
            </li>
        )
    };

    const VOTSkill = []
    for (const skills of VOT.SkillSet) {
        VOTSkill.push(
            <li>
                {skills}
            </li>
        )
    };

    return (
        <>
            <div className="box-set">
                <main className="index body-set">
                    <section className="">
                        {Clients}
                        <h4>{AS.Client}</h4>
                        <h3>{AS.Campaign}</h3>
                        <h5>
                            <ul>
                                {ASSkill}
                            </ul>
                            <Link to='/CaseStudies/AuditionShowdown' >Learn More
                            </Link>
                        </h5>
                    </section><br/>
                    <section className="odd-client">
                        <h4>{DC.Client}</h4>
                        <h3>{DC.Campaign}</h3>
                        <h5>
                            <ul>
                                {ASSkill}
                            </ul>
                            <Link to='/CaseStudies/AuditionShowdown' >Learn More
                            </Link>
                        </h5>
                    </section><br/>
                    <section>
                        <h4>{KL.Client}</h4>
                        <h3>{KL.Campaign}</h3>
                        <h5>
                            <ul>
                                {ASSkill}
                            </ul>
                            <Link to='/CaseStudies/AuditionShowdown' >Learn More
                            </Link>
                        </h5>
                    </section><br/>
                    <section className="odd-client">
                        <h4>{VOT.Client}</h4>
                        <h3>{VOT.Campaign}</h3>
                        <h5>
                            <ul>
                                {ASSkill}
                            </ul>
                            <Link to='/CaseStudies/AuditionShowdown' >Learn More
                            </Link>
                        </h5>
                    </section><br/>
                </main>
            </div>
        </>
    )
}

export default Services;