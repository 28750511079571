import React from 'react';
import { Link } from 'react-router-dom';
import ArtStationLogo from "../resources/images/client logos/Client Logos – 01 – ArtStation.svg";
import SonderLogo from "../resources/images/client logos/Client Logos – 02 – Sonder.svg";
import HopperLogo from "../resources/images/client logos/Client Logos – 03 – Hopper.svg";
import VOTLogo from "../resources/images/client logos/Client Logos – 05 – Vent Over Tea.svg";
import LabsLogo from "../resources/images/client logos/Client Logos – 06 – Khaos Labs.svg";
import ShowdownLogo from "../resources/images/client logos/Client Logos – 07 – Audition Showdown.svg";
import YPCLogo from "../resources/images/client logos/Client Logos – 08 – YPC Technologies.svg";
import CaseStudies from '../Database/CaseStudies';
import CallToAction from '../Components/CallToAction';


function Home() {
    
    const Clients = [];
    let ClientSkills = [];
    for (const cs of CaseStudies) {
        ClientSkills = [];
        for (const skills of cs.SkillSet) {
            ClientSkills.push(
                <li>{skills}</li>
            )
        }
        if (Clients.length < 3) {
            Clients.push(
                <>
                <section className=
                {Clients.length % 2 === 1 ? "odd-client": ""}
                >
                    <h4>{cs.Client}</h4>
                    <h3>{cs.Campaign}</h3>
                    <h5>
                        <ul>
                            {ClientSkills}
                        </ul>
                    </h5>
                    <h5 className="btn2">
                        <Link to={`/CaseStudies/${cs.cSName}`} >Learn More
                        </Link>
                    </h5>
                </section><br/>
            </>
            )
        }
    }
    
    return (
        <>
            <div className="box-set swatch">
                <main className="index body-set">
                    <section className="intro swatch-1">
                        <main className="">
                            <h1 className="">We make it look easy</h1>
                            <h3>We’re the creative agency helping startups rapidly scale.</h3>
                            <br/>
                            <h3 className="word-break">
                                <Link to="/Services" className="btn">
                                    Here’s how...
                                </Link>
                            </h3>
                        </main>    
                    </section>
                    <section className="body-section  brands-index swatch-2 odd">
                        <main className="brands">
                                <h3 className="past-clients">Fueling the brands you know</h3>
                                <br/>
                                <div className="past-clients">
                                    <img src={ArtStationLogo} alt="Art Station Logo" srcset="" className="past-clients-img"/>
                                    <img src={SonderLogo} alt="Sonder Logo" srcset="" className="past-clients-img"/>
                                    <img src={HopperLogo} alt="Hopper Logo" srcset="" className="past-clients-img"/>
                                </div>
                                <div className="line-break"></div>
                                <h3 className="past-clients">
                                    And, the ones you’ll discover soon enough
                                </h3>
                                <br/>
                                <div className="past-clients">
                                    <img src={VOTLogo} alt="" srcset="" className="past-clients-img"/>
                                    <img src={LabsLogo} alt="" srcset="" className="past-clients-img"/>
                                    <img src={ShowdownLogo} alt="" srcset="" className="past-clients-img"/>
                                    <img src={YPCLogo} alt="" srcset="" className="past-clients-img"/>
                                </div>
                                <div className="line-break flip"></div>
                    </main>    
                        <aside className="">
                            <h3 className="">We’ve been there before.</h3>
                            <br/>
                            <p>
                                We’ve launched and scaled startups across the continent, living the same pressures as every ground-level team as we lead them past the valley of death.
                                <br/><br/>
                                We are the marketing experts supporting passionate teams—because it always helps to have someone who’s been there and done that.
                            </p>
                        </aside>
                    </section> 
                    <section className="past-work body-section swatch-4 odd">

                            <h2>Our Past Work</h2>
                            <br/><br/><br/>
                            {Clients}
                    </section>
                <CallToAction />
                </main>
            </div>
        </>
    )
}

export default Home;