import React from 'react';

function About() {
    return (
        <>
            <div className="box-set">
                <main>
                    <section className="body-section definition">
                        <h1>Ra·men Cre·a·tiv·i·ty<sup className="h3 a-alt2"><a href="#Disclaimer">*</a></sup></h1>
                        <h2>/ˈrämən ˌkrēāˈtivədē/</h2>
                        <p>
                            <em>noun</em> (INFORMAL)
                            <br/>
                            scrappy, solution-driven thinking—especially when rapidly scaling a startup or emergent business.
                            <br/>
                            ~ <em>Apple’s “1984” commercial was a powerful demonstration of <strong>ramen creativity</strong>. After that, they were no longer the underdog. They were the competition.</em>
                            <br/>
                            Related; <a href="http://www.paulgraham.com/ramenprofitable.html" target="blank" className="a-alt2">Ramen profitiability</a>
                        </p>
                    </section>
                    <section className="swatch-2 body-section around-here">
                        <h2>Around here...</h2>
                        <div className="values">
                            <section className="">
                                <h3>Our beliefs shape our work</h3>
                                <p className="p-2">
                                    In this business, the “why” is just as important as the “what”, so we hold ourselves accountable to more than just our clients’ standards. 
                                    <br/>
                                    We hold ourselves to our own ideals as well.
                                </p>
                            </section>
                            <section className="">
                                <h3>Silos are dead</h3>
                                <p className="p-2">
                                    Interwoven solutions are no longer assets, they’re the norm. 
                                    <br/>
                                    To consider a single deliverable as a solution is to underestimate the ecosystem and undervalue the audience. 
                                </p>
                            </section>
                            <section className="">
                                <h3>Culture is king</h3>
                                <p className="p-2">
                                    It takes more than a cheque and a challenge to get us involved.
                                    <br/>
                                    If we’re going to sweat for your brand, then we need to believe in it.
                                </p>
                            </section>
                            <section>
                                <h3>Digital experiences = human experiences</h3>
                                <p className="p-2">
                                    Consider Google’s entire <a href="https://killedbygoogle.com/" target="blank" className="a-alt">graveyard</a> of duds. Now, recall all of their most respected <a href="https://about.google/intl/en_us/products/?tip=hands-free" target="blank">creations.</a> 
                                    <br/>
                                    Success begins and ends with user delight, so our work does too. 
                                </p>
                            </section>
                            <section>
                                <h3>Fuelled by iteration</h3>
                                <p>
                                    We experiment incessantly, constantly refining our processes and knowledgebase.
                                    <br/>
                                    We didn’t become experts by resting on our laurels. 
                                </p>
                            </section>
                        </div>
                    </section>
                    <aside className="main-section disclaimer swatch-4 odd">
                        <h4 className="font-1" id="Disclaimer"><strong><sup>*</sup>Disclaimer</strong></h4>
                        <p className="p-2">
                            Please note that Ramen Creative is not actually a ramen shop, nor related to any possible iteration of Japanese-inspired food. 
                            <br/><br/>
                            We’re just really big startup nerds…
                        </p>
                    </aside>
                </main>
            </div>
        </>
    )
}

export default About;