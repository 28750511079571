const CaseStudies = [
    {
        Num: '',
        cSName: 'AuditionShowdown', //Immutable Field
        Client: `Audition Showdown`,
        Campaign: 'Launch Campaign',
        SkillSet: ['User research',
                    'Strategy consultation',
                    'Content creation',
                    'Social media management'],
        Type: `Launching`,
        Subtitle: `Building performers a brand to call their own.`,
        About: `Audition Showdown is a new app that empowers new performers from across the globe to be discovered as they post videos to the platform.`,
        Challenge: `Inspired by Tik Tok and America’s Got Talent, Audition Showdown knew that they had a great idea, but weren’t sure how to launch their app and build traction with their intended audience. They called us to design and manage their social media presence in the first three weeks of the launch.`,
        Process: `We decided that the best course of action was to segment our audience by the number of followers they possessed, beginning at 500-1000 followers and ending with over 1 million. By gradually infusing each segment with interest for Showdown, we could draw attention from the next level of influencers and secure content in their tier—perpetuating the cycle.
                Our micro influencers excitedly announced our arrival to their fans and we gained an immediate following of our own—fueling valuable data that we then used to optimise each new wave of content.
                We then launched a competition with small cash prizes designed to drive up daily submissions, viewership, and attract attention from the next tier of performers, and watched our metrics soar.`,
        Results: `At the end of our three-week campaign, we reached 144.7k new viewers thanks to our 246.3k impressions. We established strong relationships with 23 micro-influencers who continued to supply Showdown with fresh content, leading to an 80% average engagement per post, hundreds of organic likes, comments, reposts, and mentions.`,
        VideoLink: ``,
    },
    {
        Num: 'odd-client',
        cSName: 'KhaosLabs', //Immutable Field
        Client: `Khaos Labs`,
        Campaign: 'Awaremess Campaign',
        SkillSet: ['Graphic design',
        'Web development',
        'Outbound marketing',
        'Social media management'],
        Type: `Promoting`,
        Subtitle: `Creating industry opportunities from temporary obstacles.`,
        About: ` A new player in the film industry, Khaos Labs is an independent studio from Montreal that produces shorts, features, and documentaries celebrating life’s most intense moments.`,
        Challenge: `After completing their first feature film in early 2020, Labs watched as Covid-19 protocols completely reshaped the year’s film circuit ecosystem and heavily disadvantaged newer arrivals to the industry so they needed a better way to get their film to market. `,
        Process: `While researching festival procedures we realised that the best time to reach distributors would be immediately after the year’s most important film market. Instead of rushing them in virtual spaces like everyone else, we wanted to capitalise on the acquisition wave that followed the event.
        We saw an opportunity to make life easy for the distributors by practicing an approach that was much more personal, much less rushed. After finding the key databases for distributor contacts, we connected with each one to discuss a deal.  
        We built all of the promotional content necessary to support a feature film’s distribution - press releases, posters, banners, movie descriptions, behind-the-scenes snapshots, the list goes on! Using social media, we built hype around the upcoming trailer release, resulting in more than 10,000 organic views in just the first week. 
        As a result, replies from distributors came quickly and the Labs team found themselves with offers almost immediately.`,
        Results: `Ultimately, Khaos Labs caught the attention of far more distributors than would have been possible through conventional approaches within the film festival circuit, even under normal circumstances.
        Thanks to this approach, Khaos Labs benefitted from a 54% open rate and 627 follow up meetings that lead to 41 deal offerings. Additionally, the unusual entrepreneurial spirit behind this campaign piqued the interest of several important industry contacts, establishing a solid reputation for the young studio.`,
        VideoLink: ``,
    },
    {
        Num: '',
        cSName: 'VentOverTea', //Immutable Field
        Client: `Vent Over Tea`,
        Campaign: 'Brand Consultation',
        SkillSet: ['Strategy sessions',
        'Brand development',
        'Production support',
        'Social media review'],
        Type: `Consulting`,
        Subtitle: `Modernising the face of help.`,
        About: ` Based in Montreal, Vent Over Tea is a free, active listening service that pairs people who need to vent with a trained empathetic listener.`,
        Challenge: `With an amazing service already launched, Vent Over Tea needed a specialist to help cement a core audience base for them to keep retention high. 
        To understand the root of their brand disparity, we went back to the original founders’ mission for the organization and then compared it with the current team’s sense of purpose. Vent Over Tea’s staff had gradually changed since its inception and their internal culture had evolved.`,
        Process: `We explored how users interacted with the organization and then looked at their main methods of communicating with their audience. We then built on their existing content to optimize their social strategies and enhance their presence across all platforms.
        Finally, we turned our attention to their funding obstacles. Vent was largely dependent on their annual fund-raising event to finance their overhead, and needed a new approach due to Covid-19. It was the perfect opportunity to transition from their reliance on a single, resource-intensive event to more evergreen strategies. 
        Among the many approaches we created for them were blueprints for a physical  Vent Over Tea Box, as well as sending curated tea collections to Montreal’s top philanthropic donors - a strong touchpoint that’s guaranteed to build awareness around Vent’s services.`,
        Results: ` Vent Over Tea is now equipped to build a strong and sustainable brand around which they can shape and scale their audience and their donor base. Their social media following has been consolidated and they’re now considering expanding to additional cities across the country.`,
        VideoLink: ``,
    },
    {
        Num: 'odd-client',
        cSName: 'DobsonCentre', //Immutable Field
        Client: `Dobson Centre`,
        Campaign: 'Awaremess Campaign',
        SkillSet: ['User research',
                    'Strategy consultation',
                    'Content creation',
                    'Social media management'],
        Type: `Promoting`,
        Subtitle: `Building a brand on the user’s real time experience.`,
        About: `McGill’s Dobson Centre for Entrepreneurship is a leading incubator that has rapidly grown since 2017. 
            The Dobson Centre had been constrained to a local presence for most of its lifetime, and now wanted to expand–so they called us.`,
        Challenge: `The Dobson Centre knew that they wanted to expose all of the work, resources, and care that they contributed to each program, so that investors understood what their involvement actually meant for the global community. The needed to build a large-scale campaign.`,
        Process: `In our initial sessions we learned about Dobson’s main audiences — aspiring founders and established investors— and their plans to connect the two through a targeted video campaign. 
            We decided to follow a handful of promising startups and record the entire experience through their eyes.
            Our recordings were expansive, providing rich details that our editing team could constantly bring to the surface as we documented the full journey, even providing for the cohort’s livestreams and pitch videos.
            The journey took us across North America, where we created demo day footage in real-time with 12h turnarounds for events in Montreal, Toronto, New York, Boston, and San Francisco. 
            For each of their programs we produced a capstone video that showcased the cohorts’ achievement.`,
        Results: ` Ultimately, the Dobson Centre received considerable attention after our two years as their content creators. McGill university contributed an extra $4 million to their funding in 2019, as did several other foundations in 2020. By the end of 2020, they had secured a spot in UBI Global’s Top 20 University Incubators and been recognized by Pitchbook University as the 2nd best undergraduate program for Canadian entrepreneurs (30th in the world).`,
        VideoLink: ``,
    },
    // {
        //     cSName: X, //Immutable Field
        //     Type: ``,
        //     client: ``,
    //     Subtitle: ``,
    //     About: ``,
    //     Challenge: ``,
    //     Process: ``,
    //     Results: ``,
    // },
]

export default CaseStudies