import React from 'react';

function Contact() {


    return (
        <>
            <div className="box-set">
                <main className="main-section odd design-guide-section">
                    <h1>Say hello<sup className="h3 a-alt2"><a href="#Unless">*</a></sup></h1>
                    <h3>
                        We want to hear from changemakers like you.
                    </h3>
                    <br/>
                    <p>
                        Get in touch to share a goal, a challenge, or simply build your network.
                        <br/>
                        We don’t have an agenda, just a passion.
                    </p>
                    <form id="khaosForm" name="email-form" data-name="Email form" method="POST" className="swatch-2 main-section design-guide-section odd">
                        <input type="hidden" name="source" value="Ramen"/>
                        <section className="Name">
                            <input type="text" placeholder="Name" name="name" data-name="Name" id="name"/>
                        </section>
                        <section className="Email">
                            <input type="email" placeholder="Email" name="email" data-name="Email" id="email" required=""/>
                        </section>
                        <section className="Message">
                            <textarea maxlength="10000" placeholder="How can we help you?" name="message" data-name="message" id="message" cols="30" rows="10"></textarea>
                        </section>
                        <br/>
                        <section className="submit-btn" id="form-submit">
                            <input type="submit" value="Send" className="btn a-alt2" data-wait="Please wait..."/>
                        </section>
                        <aside className="ramen-form-fail ramen-form" id="ramen-form-fail">
                            <p>
                                Oops! Something went wrong while submitting the form.<br/><br/>
                                Please double-check that all of the entry fields are right.
                            </p>
                        </aside>
                    </form>
                    <aside className="ramen-form-done ramen-form swatch-1 main-section design-guide-section" id="ramen-form-done">
                        <p>
                            <br/>
                            Thank you, your submission has been received!<br/><br/>
                            Someone from our team will get back to you within 24 hours.
                        </p>
                    </aside>
                <br/>
                    <h5 id="Unless">
                        *Unless you’re the person who keeps trying to place a reservation at our “ramen shop.” 
                        <br/><br/>
                        Seriously, it was funny the first time, but we don’t know the first thing about running a restaurant.<br/>Check out our friends at Toronto’s <a href="https://www.ramenisshin.com/menu" target="blank" className="a-alt">Ramen Isshin</a> for that Cha Shu Don you’re craving. We’re in the business of marketing.
                    </h5>
                </main>
                </div>
        </>
    )
}

export default Contact;