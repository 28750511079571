import React from 'react';
import CaseStudies from '../Database/CaseStudies';

const CaseStudy = ({match}) => {
    const name = match.params.name;
    const caseStudy = CaseStudies.find( cs => cs.cSName === name);
    

    return (
        <>
            <div class="box-set swatch">
                <main class="index body-set case-study">
                    <section class="intro main-section swatch-3 design-guide-section">
                        <h3>Case Study | {caseStudy.Type}</h3>
                        <h2>{caseStudy.Client}</h2>
                        <h4>
                            {caseStudy.Subtitle}
                        </h4>
                    </section>
                    <section class="about main-section swatch-1 design-guide-section">
                        <h3 class="about">
                            About
                        </h3>
                        <p>
                            {caseStudy.About}
                        </p>
                    </section>
                    <section class="main-section swatch-4 design-guide-section odd">
                        <h3 class="challenge">
                            Challenge
                        </h3>
                        <p>{caseStudy.Challenge}</p>
                    </section>
                    <section class="process main-section swatch-3 design-guide-section odd">
                        <h3 class="process">
                            Process
                        </h3>
                        <p>{caseStudy.Process}</p>
                    </section>
                    <section class="results main-section swatch-2 design-guide-section">
                        <h3 class="results">
                            Results
                        </h3>
                        <p>
                            {caseStudy.Results}
                        </p>
                    </section>
                </main> 
            </div>
        </>
    )
}

export default CaseStudy;