import React from 'react';
import { Link } from 'react-router-dom';

function Services() {
    return (
        <>
            <div className="box-set">
                <main className="index body-set">
                    <section className="intro swatch-1">
                        <main className="">
                        <h1>Call us <span className="underliner">_________________________________________________</span>
                        </h1>
                        <h3>We care about solutions<br/>not labels...</h3>
                        </main>
                    </section>
                    <section className="body-section swatch-2">
                        <h2>
                            Some of the names others have used for us...
                        </h2> 
                        <h4 className="font-1 non-em a-alt4">
                            <ul>
                                <li><a className="btn" href="#CreationStudio">Creation studio</a></li>
                                <li><a className="btn" href="#MarketingAgency">Marketing agency</a></li>
                                <li><a className="btn" href="#ProductionStudio">Production studio</a></li>
                                <li><a className="btn" href="#StrategicPartner">Strategic partner</a></li>
                            </ul>
                        </h4>  
                    </section>
                    <section className="body-section definition2 swatch-3 odd">
                        <section id="CreationStudio">
                            <h3>Creation studio</h3><br/>
                            <p>Experience designers who obsess over your user’s daily delight.</p>
                            <ul>
                                <li>Graphic design</li>
                                <li>Industrial design</li>
                                <li>User experience/interface</li>
                            </ul>
                        </section>
                        <section className="odd-client" id="MarketingAgency">
                            <h3>Marketing agency</h3><br/>
                            <p>Digital natives who craft powerful communities with a subtle touch. </p>
                            <ul>
                                <li>Branding strategy</li>
                                <li>Social media management</li>
                                <li>Website development</li>
                            </ul>
                        </section>
                        <section id="ProductionStudio">
                            <h3>Production house</h3><br/>
                            <p>Dedicated storytellers who ignite the audience’s minds with ambitions for your brand.</p>
                            <ul>
                                <li>Video production</li>
                                <li>Photography</li>
                                <li>Website development</li>
                            </ul>
                        </section>
                        <section className="odd-client" id="StrategicPartner"> 
                        <h3>Strategic partner</h3><br/>
                            <p>Business experts who analyze today’s constraints to predict tomorrow’s opportunities.</p>
                            <ul>
                                <li>User research </li>
                                <li>Project management</li>
                                <li>Boutique consultation</li>
                            </ul>
                        </section>
                    </section>
                    <section className="featured-work body-section definition2">
                        <h5>Featured Work</h5>
                        <h3>2020: A year of Khaos</h3>
                        <p>Launching at the height of Covid-19 brings new challenges to the old movie circuit.</p>
                    </section>
                    <aside className="body-section swatch-4">
                        <h3 className="non-em" >
                            Start hitting <strong>milestones</strong><br/>instead of <strong>dead zones</strong>
                            <br/><br/>
                            It’s about time you saw
                        <br/>
                            that hockey stick growth.
                        <br/><br/>
                            <Link to="/contact" className="btn btn4">Get in touch</Link>
                        </h3>
                    </aside>
                </main>
            </div>
        </>
    )
}

export default Services;